import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconCoin, IconEye, IconLockOff } from "@tabler/icons-react";
import { UserSorting, useSearchUsers } from "./api/useSearchUsers";
import { useTriggerPasswordChange } from "./api/useTriggerPasswordChange";
import { useSetUserCreditLimit } from "./api/useSetUserCreditLimit";
import { dateTimeToString } from "../../shared/utils/Dates";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";

export default function AdminUsersList() {
  const { users, searchUsers } = useSearchUsers();
  const { isSet, setCreditLimit } = useSetUserCreditLimit();
  const navigate = useNavigate();
  const { triggerPasswordChange } = useTriggerPasswordChange();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<{ field: UserSorting; asc: boolean }>({
    field: UserSorting.Email,
    asc: true,
  });
  const [filters, setFilters] = useState<{
    email: string;
    userId: string;
  }>({
    email: "",
    userId: "",
  });
  const [creditLimitDialog, setCreditLimitDialog] = useState<{
    open: boolean;
    userId: string;
    email: string;
    currentLimit: number;
  }>({
    open: false,
    userId: "",
    email: "",
    currentLimit: 0,
  });
  const [newCreditLimit, setNewCreditLimit] = useState("");

  useEffect(() => {
    searchUsers({
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      sorting: sorting.field,
      sortingAsc: sorting.asc,
      email: filters.email,
      userId: filters.userId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize, sorting, filters, isSet]);

  const handleSort = (field: UserSorting) => {
    setSorting((prev) => ({
      field,
      asc: prev.field === field ? !prev.asc : true,
    }));
  };

  const handleViewChatsClick = (userId: string) => {
    navigate(`/admin/user/${userId}/chats`);
  };

  const handleResetPasswordClick = (email: string) => {
    if (window.confirm(`Reset password for ${email}?`)) {
      triggerPasswordChange(email);
      alert(`Password reset email sent to ${email}`);
    }
  };

  const handleSetCreditLimit = () => {
    if (newCreditLimit && creditLimitDialog.userId) {
      setCreditLimit(creditLimitDialog.userId, parseInt(newCreditLimit));
      setCreditLimitDialog((prev) => ({ ...prev, open: false }));
      setNewCreditLimit("");
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex gap-4">
        <Input
          placeholder="Filter by Email"
          value={filters.email}
          onChange={(e) =>
            setFilters((prev) => ({ ...prev, email: e.target.value }))
          }
          className="max-w-xs"
        />
        <Input
          placeholder="Filter by User ID"
          value={filters.userId}
          onChange={(e) =>
            setFilters((prev) => ({ ...prev, userId: e.target.value }))
          }
          className="max-w-xs"
        />
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>UserId</TableHead>
              <TableHead
                className="cursor-pointer"
                onClick={() => handleSort(UserSorting.Email)}
              >
                Email{" "}
                {sorting.field === UserSorting.Email &&
                  (sorting.asc ? "↑" : "↓")}
              </TableHead>
              <TableHead
                className="cursor-pointer"
                onClick={() => handleSort(UserSorting.CreatedAt)}
              >
                Created At{" "}
                {sorting.field === UserSorting.CreatedAt &&
                  (sorting.asc ? "↑" : "↓")}
              </TableHead>
              <TableHead
                className="cursor-pointer"
                onClick={() => handleSort(UserSorting.CreditLimit)}
              >
                Credit Limit{" "}
                {sorting.field === UserSorting.CreditLimit &&
                  (sorting.asc ? "↑" : "↓")}
              </TableHead>
              <TableHead
                className="cursor-pointer"
                onClick={() => handleSort(UserSorting.CreditsUsed)}
              >
                Credits Used{" "}
                {sorting.field === UserSorting.CreditsUsed &&
                  (sorting.asc ? "↑" : "↓")}
              </TableHead>
              <TableHead
                className="cursor-pointer"
                onClick={() => handleSort(UserSorting.ChatsCount)}
              >
                Chats Count{" "}
                {sorting.field === UserSorting.ChatsCount &&
                  (sorting.asc ? "↑" : "↓")}
              </TableHead>
              <TableHead>View chats</TableHead>
              <TableHead>Reset password</TableHead>
              <TableHead>Set credit limit</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {users?.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{dateTimeToString(user.createdAt)}</TableCell>
                <TableCell>{user.creditLimit}</TableCell>
                <TableCell>{user.creditsUsed}</TableCell>
                <TableCell>{user.chatsCount}</TableCell>
                <TableCell>
                  <Button
                    size="icon"
                    variant="outline"
                    onClick={() => handleViewChatsClick(user.id)}
                  >
                    <IconEye className="h-4 w-4" />
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    size="icon"
                    variant="outline"
                    onClick={() => handleResetPasswordClick(user.email)}
                  >
                    <IconLockOff className="h-4 w-4" />
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    size="icon"
                    variant="outline"
                    onClick={() =>
                      setCreditLimitDialog({
                        open: true,
                        userId: user.id,
                        email: user.email,
                        currentLimit: user.creditLimit,
                      })
                    }
                  >
                    <IconCoin className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            onClick={() =>
              setPagination((prev) => ({
                ...prev,
                pageIndex: Math.max(0, prev.pageIndex - 1),
              }))
            }
            disabled={pagination.pageIndex === 0}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            onClick={() =>
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex + 1,
              }))
            }
          >
            Next
          </Button>
        </div>
        <div>Page {pagination.pageIndex + 1}</div>
      </div>

      <Dialog
        open={creditLimitDialog.open}
        onOpenChange={(open) =>
          setCreditLimitDialog((prev) => ({ ...prev, open }))
        }
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Set Credit Limit</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="mb-4">
              Enter new credit limit for {creditLimitDialog.email}
            </p>
            <Input
              type="number"
              value={newCreditLimit}
              onChange={(e) => setNewCreditLimit(e.target.value)}
              placeholder={creditLimitDialog.currentLimit.toString()}
            />
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() =>
                setCreditLimitDialog((prev) => ({ ...prev, open: false }))
              }
            >
              Cancel
            </Button>
            <Button onClick={handleSetCreditLimit}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
