import { dateTimeToString } from "../../../shared/utils/Dates";
import { ChatShort } from "../../../shared/models/Chat";

interface ChatInfoProps {
  chat: ChatShort;
}

export default function ChatInfo({ chat }: ChatInfoProps) {
  if (!chat || !chat.startedAt || !chat.settings) {
    return null;
  }

  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <span className="text-base font-medium">
          Date: {dateTimeToString(chat.startedAt)}
        </span>
      </div>
      <div className="space-y-1">
        <span className="text-base font-medium">Used settings:</span>
        <div className="flex flex-wrap gap-2">
          {Object.entries(chat.settings).map(([key, value], index) => (
            <span key={index} className="text-sm text-muted-foreground">
              {key}: {value.toString()}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
