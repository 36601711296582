import { useEffect, useRef } from "react";
import { dateTimeToString } from "../../../shared/utils/Dates";
import { Transcript } from "../models/Transcript";

interface TranscriptContentProps {
  transcript: Transcript;
}

export default function TranscriptContent({
  transcript,
}: TranscriptContentProps) {
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!transcript.selectedChunkText || !targetRef.current) return;
    targetRef.current.scrollIntoView({ behavior: "auto", block: "center" });
  }, [transcript.selectedChunkText]);

  let targetComponentId = -1;
  let chunkStartIndex = -1;

  // find the component that contains the selected chunk
  if (transcript.selectedChunkText) {
    for (const component of transcript.components) {
      if (
        component.text &&
        component.text.includes(transcript.selectedChunkText)
      ) {
        targetComponentId = component.id;
        chunkStartIndex = component.text.indexOf(transcript.selectedChunkText);
        break;
      }
    }
  }

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <h2 className="text-2xl font-semibold tracking-tight">
          {transcript.title}
        </h2>
        <p className="text-sm text-muted-foreground">
          {dateTimeToString(transcript.date)}
        </p>
      </div>

      <div className="space-y-6">
        {transcript.components.map((component) => (
          <div key={component.id} className="space-y-2">
            <div className="font-medium text-foreground">
              {component.person}
            </div>
            {component.id === targetComponentId ? (
              <div className="text-sm leading-relaxed">
                {component.text && chunkStartIndex != -1 ? (
                  <>
                    {component.text.substring(0, chunkStartIndex)}
                    <div className="inline" ref={targetRef}>
                      <mark className="bg-yellow-200 dark:bg-yellow-800">
                        {transcript.selectedChunkText}
                      </mark>
                    </div>
                    {component.text.substring(
                      chunkStartIndex + transcript.selectedChunkText!.length,
                    )}
                  </>
                ) : (
                  component.text
                )}
              </div>
            ) : (
              <div className="text-sm leading-relaxed">{component.text}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
