import { useEffect, useState, useRef } from "react";
import { useGetCompany } from "@/shared/api/useGetCompany";
import { useGetCompanyDocumentSource } from "@/features/sources/api/useGetCompanyDocumentSource";
import { CompanyInfo } from "@/shared/components/company-info/models/CompanyInfo";
import { CompanyDocText } from "@/features/sources/models/CompanyDocText";
import { CitationSourceType } from "@/shared/enums/CitationSourceType";

export interface MetadataCache {
  companies: Record<string, CompanyInfo>;
  documents: Record<string, CompanyDocText>;
}

export function useMetadataResolver() {
  const { getCompany, company } = useGetCompany();
  const { getCompanyDocumentSource, source } = useGetCompanyDocumentSource();
  const [cache, setCache] = useState<MetadataCache>({
    companies: {},
    documents: {},
  });
  const pendingRequests = useRef<Set<string>>(new Set());
  const lastRequestKey = useRef<string>("");

  // Update cache when company data changes
  useEffect(() => {
    if (company) {
      setCache((prev) => ({
        ...prev,
        companies: {
          ...prev.companies,
          [company.id]: company,
        },
      }));
      pendingRequests.current.delete(`company-${company.id}`);
    }
  }, [company]);

  // Update cache when document data changes
  useEffect(() => {
    if (source) {
      const key = `${source.type?.toLowerCase()}-${source.id}`;
      setCache((prev) => ({
        ...prev,
        documents: {
          ...prev.documents,
          [key]: source,
        },
      }));
      pendingRequests.current.delete(key);
    }
  }, [source]);

  const resolveMetadata = async (
    companyIds: string,
    filingIds: string,
    transcriptIds: string,
  ) => {
    // Create a unique key for this request combination
    const requestKey = `${companyIds}|${filingIds}|${transcriptIds}`;

    // Skip if this is the same request we just processed
    if (requestKey === lastRequestKey.current) {
      return {
        companies: Object.values(cache.companies),
        documents: Object.values(cache.documents),
      };
    }

    lastRequestKey.current = requestKey;

    const companyIdList = companyIds?.split(",").filter(Boolean) ?? [];
    const filingIdList = filingIds?.split(",").filter(Boolean) ?? [];
    const transcriptIdList = transcriptIds?.split(",").filter(Boolean) ?? [];

    // Create a map to track which requests have been initiated
    const requestMap = new Map<string, Promise<void>>();

    // Fetch missing companies that aren't already being fetched
    for (const id of companyIdList) {
      const requestKey = `company-${id}`;
      if (
        !cache.companies[id] &&
        !pendingRequests.current.has(requestKey) &&
        !requestMap.has(requestKey)
      ) {
        const promise = new Promise<void>((resolve) => {
          pendingRequests.current.add(requestKey);
          getCompany(parseInt(id))
            .then(() => {
              // The company data will be handled by the useEffect hook when it updates
            })
            .catch((error) => {
              console.error(`Error fetching company ${id}:`, error);
            })
            .finally(() => {
              pendingRequests.current.delete(requestKey);
              resolve();
            });
        });
        requestMap.set(requestKey, promise);
      }
    }

    // Fetch missing documents that aren't already being fetched
    for (const id of filingIdList) {
      const docKey = `filing-${id}`;
      if (
        !cache.documents[docKey] &&
        !pendingRequests.current.has(docKey) &&
        !requestMap.has(docKey)
      ) {
        const promise = new Promise<void>((resolve) => {
          pendingRequests.current.add(docKey);
          getCompanyDocumentSource(CitationSourceType.Filing, id)
            .then(() => {
              // The document data will be handled by the useEffect hook when it updates
            })
            .catch((error) => {
              console.error(`Error fetching filing ${id}:`, error);
            })
            .finally(() => {
              pendingRequests.current.delete(docKey);
              resolve();
            });
        });
        requestMap.set(docKey, promise);
      }
    }

    for (const id of transcriptIdList) {
      const docKey = `transcript-${id}`;
      if (
        !cache.documents[docKey] &&
        !pendingRequests.current.has(docKey) &&
        !requestMap.has(docKey)
      ) {
        const promise = new Promise<void>((resolve) => {
          pendingRequests.current.add(docKey);
          getCompanyDocumentSource(CitationSourceType.Transcript, id)
            .then(() => {
              // The document data will be handled by the useEffect hook when it updates
            })
            .catch((error) => {
              console.error(`Error fetching transcript ${id}:`, error);
            })
            .finally(() => {
              pendingRequests.current.delete(docKey);
              resolve();
            });
        });
        requestMap.set(docKey, promise);
      }
    }

    // Wait for all requests to complete
    if (requestMap.size > 0) {
      await Promise.all(requestMap.values());
      // Give React a chance to process state updates
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    // Return immediately with what's in cache
    return {
      companies: companyIdList.map((id) => cache.companies[id]).filter(Boolean),
      documents: [
        ...filingIdList.map((id) => cache.documents[`filing-${id}`]),
        ...transcriptIdList.map((id) => cache.documents[`transcript-${id}`]),
      ].filter(Boolean),
    };
  };

  return { resolveMetadata, cache };
}
