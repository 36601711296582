import { CitationSourceType } from "../../../shared/enums/CitationSourceType";
import { Citation } from "./Citation";

export class ContentWithCitations {
  public citations: Citation[];
  public formattedContent: string;

  public constructor(content: string) {
    // Extract citations from the content in format [documentType:documentId:chunkId(optional)]
    const citationRegex = /\[([^\]:]+):([^\]:]+)(?::([^\]]+))?\]/g;
    let match;
    this.citations = [];

    let formattedContent = content;

    while ((match = citationRegex.exec(content)) !== null) {
      const citationText = match[0];

      // Check if match[1] is in CitationSourceType enum. If not, remove and continue
      if (
        !Object.values(CitationSourceType).includes(
          match[1] as CitationSourceType,
        )
      ) {
        formattedContent = formattedContent.replace(citationText, "");
        continue;
      }
      const sourceType = match[1] as CitationSourceType;
      const documentId = match[2];
      const chunkId = match[3];

      const citationIndex = this.citations.length;
      this.citations.push(new Citation(sourceType, documentId, chunkId));
      const replacement = ` \`[citation:${citationIndex}]\` `;
      formattedContent = formattedContent.replace(citationText, replacement);
    }

    this.formattedContent = formattedContent;
  }
}
