import { ScrollArea } from "@/components/ui/scroll-area";
import classes from "./styles/ScrollableText.module.css";

interface ScrollableTextProps {
  content?: string;
}

export default function ScrollableText({ content }: ScrollableTextProps) {
  return (
    <ScrollArea className={classes["scrollarea"]}>
      <p className={classes["text"]}>{content}</p>
    </ScrollArea>
  );
}
