import { useEffect } from "react";
import { useGetFiling } from "./api/useGetFiling";
import FilingContent from "./components/FilingContent";
import { useResetHash } from "../../shared/hooks/useResetHash";
import { Skeleton } from "@/components/ui/skeleton";

interface FilingBodyProps {
  id: string;
  chunkId?: number;
}

export default function FilingBody({ id, chunkId }: FilingBodyProps) {
  const { filing, getFiling, isLoading } = useGetFiling();

  useEffect(() => {
    getFiling(id, chunkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, chunkId]);

  useResetHash(filing);

  return (
    <div className="h-full p-8">
      {isLoading && (
        <div className="h-full w-full flex items-center justify-center">
          <Skeleton className="h-12 w-12 rounded-full" />
        </div>
      )}
      {filing && <FilingContent filing={filing} />}
    </div>
  );
}
