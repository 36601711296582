import { useState } from "react";
import { IconEdit } from "@tabler/icons-react";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

interface EditableTextInputProps {
  text: string;
  isGradient?: boolean;
  textClassname?: string;
  placeholder: string;
  inputSize?: "xs" | "sm" | "md" | "lg" | "xl";
  inputClassname?: string;
  onSave: (value: string) => void;
}

export function EditableTextInput({
  text,
  isGradient = false,
  textClassname,
  placeholder,
  inputSize = "xs",
  inputClassname,
  onSave,
}: EditableTextInputProps) {
  const [editMode, setEditMode] = useState(text === "");
  const [value, setValue] = useState(text);

  const handleEdit = () => setEditMode(true);
  const handleSave = () => {
    onSave(value);
    if (value.replace(/\s/g, "") !== "") setEditMode(false);
  };

  const getInputSize = () => {
    switch (inputSize) {
      case "xs":
        return "h-7 text-sm";
      case "sm":
        return "h-8 text-sm";
      case "md":
        return "h-9 text-base";
      case "lg":
        return "h-10 text-lg";
      case "xl":
        return "h-11 text-xl";
      default:
        return "h-7 text-sm";
    }
  };

  return (
    <div className="flex items-center">
      {editMode && (
        <Input
          value={value}
          placeholder={placeholder}
          className={cn(
            getInputSize(),
            value.replace(/\s/g, "") === "" && "border-red-500",
            inputClassname,
          )}
          autoFocus
          onChange={(event) => setValue(event.target.value)}
          onBlur={handleSave}
        />
      )}
      {!editMode && (
        <>
          {isGradient ? (
            <div
              onClick={handleEdit}
              className="flex h-9 items-center gap-2.5 cursor-pointer"
            >
              <span
                className={cn(
                  "text-2xl font-bold bg-gradient-to-br from-[#295c8b] via-[#295c8b] to-[#295c8b] bg-clip-text text-transparent",
                  textClassname,
                )}
              >
                {value}
              </span>
              <IconEdit className="text-tertiary" size={24} />
            </div>
          ) : (
            <div
              onClick={handleEdit}
              className="flex h-7.5 items-center gap-2.5 cursor-pointer"
            >
              <span className={textClassname}>{value}</span>
              <IconEdit size={16} className="text-tertiary" />
            </div>
          )}
        </>
      )}
    </div>
  );
}
