import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { PromptVersion } from "../models/PromptVersion";
import { useState } from "react";

interface PromptVersionProps {
  version: PromptVersion;
  index: number;
  promptVersions: PromptVersion[];
  revertToVersion: (version: PromptVersion) => void;
}

export default function PromptVersionView({
  version,
  index,
  promptVersions,
  revertToVersion,
}: PromptVersionProps) {
  const [isDiffWithCurrent, setIsDiffWithCurrent] = useState(false);
  const [isSplitView, setIsSplitView] = useState(true);

  return (
    <AccordionItem
      value={`${version.createdAt.toString()} | ${version.authorEmail} ${index === 0 ? " (currently active version)" : ""}`}
    >
      <AccordionTrigger>
        {`${version.createdAt.toString()} | ${version.authorEmail} ${index === 0 ? " (currently active version)" : ""}`}
      </AccordionTrigger>
      <AccordionContent>
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            {index === promptVersions.length - 1 ? (
              <div>temp: {version.temperature}</div>
            ) : (
              <div>
                temp:{" "}
                {isDiffWithCurrent
                  ? promptVersions[0].temperature
                  : promptVersions[index + 1].temperature}
                {isDiffWithCurrent
                  ? " (currently active version)"
                  : " (previous version)"}
                {"  -->  "}
                {version.temperature} (this version)
              </div>
            )}

            <div>|</div>
            {index === promptVersions.length - 1 ? (
              <div>maxTokens: {version.maxTokens}</div>
            ) : (
              <div>
                maxTokens:{" "}
                {isDiffWithCurrent
                  ? promptVersions[0].maxTokens
                  : promptVersions[index + 1].maxTokens}
                {isDiffWithCurrent
                  ? " (currently active version)"
                  : " (previous version)"}
                {"  -->  "}
                {version.maxTokens} (this version)
              </div>
            )}
          </div>

          {index !== promptVersions.length - 1 && (
            <>
              <div className="font-bold">
                {isDiffWithCurrent
                  ? "Showing the difference with the currently active version. Current version is on the left, this version is on the right."
                  : "Showing the difference with the previous version that is below this one. Previous version is on the left, this version is on the right."}
              </div>
              <div className="max-h-[700px] overflow-auto">
                <ReactDiffViewer
                  oldValue={
                    isDiffWithCurrent
                      ? promptVersions[0].text
                      : promptVersions[index + 1].text
                  }
                  newValue={version.text}
                  leftTitle={
                    isDiffWithCurrent
                      ? "Currently active version"
                      : "Previous version"
                  }
                  rightTitle={"This version"}
                  splitView={isSplitView}
                  compareMethod={DiffMethod.WORDS}
                  styles={{
                    contentText: {
                      fontSize: "11px",
                    },
                    line: {
                      wordBreak: "break-word",
                    },
                  }}
                />
              </div>
            </>
          )}

          {index === promptVersions.length - 1 ? (
            <>
              <div className="font-bold">Prompt Text:</div>
              <div className="max-h-[700px] overflow-auto">
                <pre className="text-xs">{version.text}</pre>
              </div>
            </>
          ) : (
            <div className="flex space-x-4">
              <Button
                variant="outline"
                onClick={() => setIsDiffWithCurrent(!isDiffWithCurrent)}
              >
                {isDiffWithCurrent
                  ? "Show difference with previous version"
                  : "Show difference with current version"}
              </Button>
              <Button
                variant="outline"
                onClick={() => setIsSplitView(!isSplitView)}
              >
                {isSplitView ? "Show inline view" : "Show split view"}
              </Button>
              <Button onClick={() => revertToVersion(version)}>
                Revert to this version
              </Button>
            </div>
          )}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
}
