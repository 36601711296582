import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Settings, ArrowLeft } from "lucide-react";
import { useState, useEffect, useCallback } from "react";
import SettingsModal from "../features/header/components/Settings";
import ChatItemList from "../features/chat-management/components/ChatItemList";
import { useGetChats } from "../features/chat-management/api/useGetChats";
import AdminChat from "../features/admin/components/AdminChat";

export default function AdminUserChats() {
  const { userId, chatId } = useParams();
  const navigate = useNavigate();
  const [settingsOpened, setSettingsOpened] = useState(false);
  const { chats, getChats } = useGetChats(userId);

  const fetchChats = useCallback(() => {
    getChats({ page: 1, perPage: 50 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]); // Only depend on userId, not getChats

  useEffect(() => {
    fetchChats();
  }, [fetchChats]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="bg-white border-b border-gray-100">
        <div className="container mx-auto w-full max-w-[95%] p-4">
          <div className="flex justify-between items-center">
            <div>
              <div className="flex items-center gap-3 mb-1">
                <Button
                  variant="ghost"
                  size="sm"
                  className="text-gray-600 gap-1.5 text-sm h-8"
                  onClick={() => navigate("/admin")}
                >
                  <ArrowLeft className="h-4 w-4" />
                  Back to Admin
                </Button>
              </div>
              <h1 className="text-2xl font-bold text-gray-900">User Chats</h1>
              <p className="text-gray-500 text-base">
                View and manage user chat history
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                size="sm"
                className="text-gray-600 gap-1.5 text-sm h-8"
                onClick={() => setSettingsOpened(true)}
              >
                <Settings className="h-4 w-4" />
                Settings
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto w-full max-w-[95%] p-4">
        <div className="grid grid-cols-[minmax(28rem,1fr)_2fr] gap-8">
          <div className="space-y-4 min-w-0">
            <ChatItemList
              chats={chats || []}
              userId={userId}
              onChatClick={(chat) =>
                navigate(`/admin/user/${userId}/chat/${chat.id}`)
              }
            />
          </div>
          <div className="border-l border-gray-100 pl-8 min-w-0">
            {chatId && userId && (
              <AdminChat chatId={chatId} userId={userId} autoScroll={false} />
            )}
          </div>
        </div>
      </div>

      <SettingsModal
        opened={settingsOpened}
        close={() => setSettingsOpened(false)}
      />
    </div>
  );
}
