import { Badge } from "@/components/ui/badge";
import { GetMarketDataMessageData } from "../../models/message-data/GetMarketDataMessageData";
import FunctionCall from "../FunctionCall";

interface GetMarketDataMessageProps {
  data: GetMarketDataMessageData;
}

export default function GetMarketDataMessage({
  data,
}: GetMarketDataMessageProps) {
  return (
    <>
      {data.data.map((data, index) => (
        <div key={index} className="flex items-center gap-2">
          <p>{data.toViewString()}</p>
          <Badge variant="default">{data.source}</Badge>
        </div>
      ))}
      <FunctionCall functionCalls={[data.functionCall]} />
    </>
  );
}
