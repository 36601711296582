import { MessageDataType } from "../enums/MessageDataType";
import { ChatMessage } from "./ChatMessage";
import { ChatMessageData } from "./ChatMessageData";
import { ExecuteCodeMessageData } from "./message-data/ExecuteCodeMessageData";
import { AskDocumentMessageData } from "./message-data/AskDocumentMessageData";
import { GetMarketDataMessageData } from "./message-data/GetMarketDataMessageData";
import { ModelOutputMessageData } from "./message-data/ModelOutputMessageData";
import { UserInputMessageData } from "./message-data/UserInputMessageData";
import ExecuteCodeMessage from "../components/messages/ExecuteCodeMessage";
import GetMarketDataMessage from "../components/messages/GetMarketDataMessage";
import ModelOutputMessage from "../components/messages/ModelOutputMessage";
import { FunctionCallMessageData } from "./message-data/FunctionCallMessageData";
import FunctionCallMessage from "../components/messages/FunctionCallMessage";
import AskDocumentMessage from "../components/messages/AskDocumentMessage";
import { FTSMessageData } from "./message-data/FTSMessageData";
import { FilterDocumentsMessageData } from "./message-data/FilterDocumentsMessageData";
import { AskCompanyDocumentsMessageData } from "./message-data/AskCompanyDocumentsMessageData";
import { Citation } from "../../sources/models/Citation";

type MessageDataAttributesType = {
  [key in MessageDataType]: {
    constructor: (data: any, messages: ChatMessage[]) => ChatMessageData;
    component: (
      data: ChatMessageData,
      isInProgress?: boolean,
      onCitationClick?: (citation: Citation) => void,
    ) => JSX.Element;
    isShown: boolean;
  };
};

const messageDataAttributes: MessageDataAttributesType = {
  [MessageDataType.UserInputMessageData]: {
    constructor: (data: any) => new UserInputMessageData(data),
    component: () => <></>,
    isShown: true,
  },
  [MessageDataType.ModelOutputMessageData]: {
    constructor: (data: any) => new ModelOutputMessageData(data),
    component: (
      data: ChatMessageData,
      _isInProgress?: boolean,
      onCitationClick?: (citation: Citation) => void,
    ) => (
      <ModelOutputMessage
        data={data as ModelOutputMessageData}
        onCitationClick={onCitationClick}
      />
    ),
    isShown: true,
  },
  [MessageDataType.GetMarketDataMessageData]: {
    constructor: (data: any) => new GetMarketDataMessageData(data),
    component: (data: ChatMessageData) => (
      <GetMarketDataMessage data={data as GetMarketDataMessageData} />
    ),
    isShown: true,
  },
  [MessageDataType.ExecuteCodeMessageData]: {
    constructor: (data: any) => new ExecuteCodeMessageData(data),
    component: (data: ChatMessageData) => (
      <ExecuteCodeMessage data={data as ExecuteCodeMessageData} />
    ),
    isShown: true,
  },
  [MessageDataType.FunctionCallMessageData]: {
    constructor: (data: any) => new FunctionCallMessageData(data),
    component: (data: ChatMessageData) => (
      <FunctionCallMessage data={data as FunctionCallMessageData} />
    ),
    isShown: false,
  },
  [MessageDataType.AskDocumentMessageData]: {
    constructor: (data: any) => new AskDocumentMessageData(data),
    component: (data: ChatMessageData) => (
      <AskDocumentMessage data={data as AskDocumentMessageData} />
    ),
    isShown: false,
  },
  [MessageDataType.FTSMessageData]: {
    constructor: (data: any) => new FTSMessageData(data),
    component: (data: ChatMessageData) => (
      <FunctionCallMessage data={data as FunctionCallMessageData} />
    ),
    isShown: false,
  },
  [MessageDataType.FilterDocumentsMessageData]: {
    constructor: (data: any) => new FilterDocumentsMessageData(data),
    component: (data: ChatMessageData) => (
      <FunctionCallMessage data={data as FunctionCallMessageData} />
    ),
    isShown: false,
  },
  [MessageDataType.AskCompanyDocumentsMessageData]: {
    constructor: (data: any) => new AskCompanyDocumentsMessageData(data),
    component: (data: ChatMessageData) => (
      <FunctionCallMessage data={data as FunctionCallMessageData} />
    ),
    isShown: false,
  },
};

export class MessageProcessor {
  static GetAttributes(type: MessageDataType) {
    let attributes = messageDataAttributes[type as MessageDataType];
    if (!attributes)
      attributes =
        messageDataAttributes[MessageDataType.FunctionCallMessageData];

    return attributes;
  }

  static ParseData(data: any, messages: ChatMessage[]): ChatMessageData {
    if (!data) throw new Error("Data is empty");
    if (!data.type) throw new Error("Data type is empty");
    return this.GetAttributes(data.type).constructor(data, messages);
  }

  static GetComponent(
    data: ChatMessageData,
    isInProgress?: boolean,
    onCitationClick?: (citation: Citation) => void,
  ): JSX.Element {
    const attributes = MessageProcessor.GetAttributes(data.type);
    return attributes.component(data, isInProgress, onCitationClick);
  }

  static getFunctionTitle(functionName: string): string {
    // Convert snake_case to Title Case
    return functionName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  static ProccessUpdate(
    messages: ChatMessage[],
    updated: ChatMessage,
  ): ChatMessage[] {
    const existingMessageIndex = messages.findIndex((m) => m.id === updated.id);
    if (existingMessageIndex !== -1) {
      messages[existingMessageIndex] = updated;
    } else {
      messages.push(updated);
    }
    const sortedSteps = messages.sort(
      (a, b) => +new Date(a.startedAt) - +new Date(b.startedAt),
    );
    return [...sortedSteps];
  }

  static ProcessContentUpdate(
    messages: ChatMessage[],
    messageId: string,
    content: string,
  ): ChatMessage[] {
    const message = messages.find((m) => m.id === messageId);
    if (!message) return messages;
    let messageData = message.data as ModelOutputMessageData;
    if (!messageData) {
      messageData = new ModelOutputMessageData({
        type: MessageDataType.ModelOutputMessageData,
      });
    }
    messageData.content = (messageData.content || "") + content;
    message.data = messageData;
    return MessageProcessor.ProccessUpdate(messages, message);
  }

  static showMessage(
    message: ChatMessage,
    isAdmin: boolean,
    isLast: boolean,
  ): boolean {
    // Show only UserInput and ModelOutput without function call
    if (isAdmin) return true;
    if (!message.finishedAt) return true;
    if (message.error && isLast) return true;
    if (!message.data) return true;

    if (message.data.type === MessageDataType.UserInputMessageData) return true;
    if (message.data.type === MessageDataType.ModelOutputMessageData) {
      const data = message.data as ModelOutputMessageData;
      return !!data.content;
    }

    return this.GetAttributes(message.data.type).isShown;
  }
}
