import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocalStorage } from "@mantine/hooks";
import { LocalStorageKeys } from "../../enums/LocalStorageKeys";
import CenterLoader from "../loader/CenterLoader";

export default function AuthProtectedRoute() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [, saveRedirectUrl] = useLocalStorage<string>({
    key: LocalStorageKeys.RedirectUrl,
  });

  useEffect(() => {
    if (isAuthenticated) return;

    saveRedirectUrl(window.location.pathname);
    loginWithRedirect();
  }, [isAuthenticated, loginWithRedirect, saveRedirectUrl]);

  return isAuthenticated ? <Outlet /> : <CenterLoader />;
}
