import FunctionCall from "../FunctionCall";
import { ExecuteCodeMessageData } from "../../models/message-data/ExecuteCodeMessageData";
import ChartVisualisation from "../../../../shared/components/chart/ChartVisualisation";

interface ExecuteCodeMessageProps {
  data: ExecuteCodeMessageData;
}

export default function ExecuteCodeMessage({ data }: ExecuteCodeMessageProps) {
  return (
    <>
      <div>
        {data.chart && (
          <div className="flex flex-col items-center gap-0">
            <ChartVisualisation chart={data.chart} />
          </div>
        )}
        <div className="flex justify-between">
          {!data.chart && data.data && (
            <div className="max-w-[90%]">
              <p className="text-sm">
                Data that was returned by execution server:
              </p>
              <p className="text-sm whitespace-pre-wrap">{data.data}</p>
            </div>
          )}
        </div>
      </div>
      <FunctionCall functionCalls={[data.functionCall]} />
    </>
  );
}
