import { useHasAdminRole } from "../../../shared/hooks/useHasRole";
import { useAdminView } from "../../../shared/hooks/useAdminView";
import { useState } from "react";
import JsonFormatter from "react-json-formatter";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";

interface FunctionCallProps {
  functionCalls?: any[];
}

export default function FunctionCall({ functionCalls }: FunctionCallProps) {
  const isAdmin = useHasAdminRole();
  const [isAdminView] = useAdminView();
  const [isOpen, setIsOpen] = useState(false);

  try {
    functionCalls = functionCalls?.map((fc: any) => {
      if (fc.arguments) {
        fc.arguments = JSON.parse(fc.arguments);
      }
      return fc;
    });
  } catch {
    /* empty */
  }

  if (!isAdmin || !isAdminView || !functionCalls) {
    return null;
  }

  return (
    <div className="space-y-4">
      <Collapsible open={isOpen} onOpenChange={setIsOpen}>
        <CollapsibleTrigger asChild>
          <Button variant="outline" size="sm" className="w-[150px]">
            {isOpen
              ? `Hide function call${functionCalls.length > 1 ? "s" : ""}`
              : `Show ${functionCalls.length} function call${functionCalls.length > 1 ? "s" : ""}`}
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="flex flex-col gap-2 mt-2">
            {functionCalls.map((fc, index) => (
              <ScrollArea
                className="h-[400px] w-full rounded-md border p-4"
                key={index}
              >
                <pre className="text-sm">
                  <JsonFormatter json={fc} />
                </pre>
              </ScrollArea>
            ))}
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
}
