import { useState } from "react";
import {
  QCustomEvent,
  useEventListener,
  useEventTrigger,
} from "./useEventManagement";

export const useAdminView = () => {
  const getAdminView = () => localStorage.getItem("adminView") === "true";
  const [isAdminView, setIsAdminView] = useState<boolean>(getAdminView);
  const dispatchEvent = useEventTrigger();

  // Listen for the custom event to update the admin view
  useEventListener(QCustomEvent.AdminViewChangeEvent, () =>
    setIsAdminView(getAdminView()),
  );

  const toggleAdminView = (value: boolean) => {
    localStorage.setItem("adminView", value.toString());
    setIsAdminView(value);
    // Trigger the custom event to notify other components of the change
    dispatchEvent(QCustomEvent.AdminViewChangeEvent);
  };

  return [isAdminView, toggleAdminView] as const;
};
