import { useEffect, useRef } from "react";

export enum QCustomEvent {
  AdminViewChangeEvent = "adminViewChangeEvent",
  RefreshCompanyTemplate = "refreshCompanyTemplate",
  RefreshCompanySections = "refreshCompanySections",
  RefreshWatchlists = "refreshWatchlists",
  RefreshTemplates = "refreshTemplates",
  RefreshQuestions = "refreshQuestions",
  RefreshPlaybooks = "refreshPlaybooks",
  RefreshChats = "refreshChats",
}

export const useEventListener = (
  event: QCustomEvent,
  handler: () => void,
  dependencies: any[] = [],
): void => {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler, ...dependencies]);

  useEffect(() => {
    const memoizedHandler = () => handlerRef.current();
    window.addEventListener(event, memoizedHandler);
    return () => {
      window.removeEventListener(event, memoizedHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useEventTrigger = () => {
  const dispatchEvent = (event: QCustomEvent) => {
    window.dispatchEvent(new Event(event));
  };
  return dispatchEvent;
};
