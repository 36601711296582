import { useEffect } from "react";
import { useGetTranscript } from "./api/useGetTranscript";
import TranscriptContent from "./components/TranscriptContent";
import { useResetHash } from "../../shared/hooks/useResetHash";
import CenterLoader from "@/shared/components/loader/CenterLoader";

interface TranscriptBodyProps {
  id: number;
  chunkId?: number;
}

export default function TranscriptBody({ id, chunkId }: TranscriptBodyProps) {
  const { transcript, getTranscript, isLoading } = useGetTranscript();

  useEffect(() => {
    getTranscript(id, chunkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, chunkId]);

  useResetHash(transcript);

  return (
    <div className="h-full p-8">
      {isLoading && <CenterLoader />}
      {transcript && <TranscriptContent transcript={transcript} />}
    </div>
  );
}
