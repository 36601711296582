import { useEffect, useRef, useState } from "react";
import { Chat } from "../../../shared/models/Chat";
import { ChatMessage } from "../../existing-chat/models/ChatMessage";
import { useGetAdminChat } from "../api/useGetAdminChat";
import { useGetMessages } from "../../existing-chat/api/useGetMessages";
import CenterLoader from "../../../shared/components/loader/CenterLoader";
import MessageList from "../../existing-chat/components/MessageList";
import { Citation } from "../../sources/models/Citation";

interface AdminChatProps {
  chatId: string;
  userId: string;
  autoScroll?: boolean;
  onCitationClick?: (citation: Citation) => void;
}

export default function AdminChat({
  chatId,
  userId,
  autoScroll = true,
  onCitationClick,
}: AdminChatProps) {
  const { chat, getAdminChat } = useGetAdminChat(chatId, userId);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const { messages: updatedMessages } = useGetMessages(chatId);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const setChatProps = (chat?: Chat) => {
    setMessages(chat?.messages?.filter((msg) => msg !== undefined) || []);
  };

  useEffect(() => {
    // Clear all the states before fetching the chat
    setChatProps();
    getAdminChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, userId]);

  useEffect(() => {
    if (!chat) return;
    setChatProps(chat);
  }, [chat]);

  useEffect(() => {
    if (!updatedMessages) return;
    setMessages(updatedMessages);
  }, [updatedMessages]);

  useEffect(() => {
    if (isInitialLoad && messages.length > 0) {
      setIsInitialLoad(false);
    }
  }, [isInitialLoad, messages]);

  // Scroll handling
  useEffect(() => {
    if (!autoScroll || !scrollContainerRef.current || !isAtBottom) return;

    const scrollContainer = scrollContainerRef.current;
    scrollContainer.scrollTop = scrollContainer.scrollHeight;
  }, [messages, autoScroll, isAtBottom]);

  const handleScroll = () => {
    if (!scrollContainerRef.current) return;

    const scrollContainer = scrollContainerRef.current;
    const isBottom =
      Math.abs(
        scrollContainer.scrollHeight -
          scrollContainer.clientHeight -
          scrollContainer.scrollTop,
      ) < 1;

    setIsAtBottom(isBottom);
  };

  if (!chat && isInitialLoad) {
    return <CenterLoader />;
  }

  return (
    <div
      className="flex flex-col h-full overflow-hidden"
      ref={scrollContainerRef}
      onScroll={handleScroll}
    >
      {chat && (
        <MessageList
          messages={messages}
          onCitationClick={onCitationClick}
          chat={chat}
          autoScroll={autoScroll}
        />
      )}
      <div ref={bottomRef} />
    </div>
  );
}
