import { useEffect, useState } from "react";
import { dateTimeToString } from "../../shared/utils/Dates";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useGetDefaultPlaybooks } from "./api/useGetDefaultPlaybooks";
import { useDeleteDefaultPlaybook } from "./api/useDeleteDefaultPlaybook";
import EditPlaybookModal from "./components/EditPlaybookModal";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

export default function AdminPlaybooks() {
  const { defaultPlaybooks, getDefaultPlaybooks } = useGetDefaultPlaybooks();
  const { isDeleted, deletePlaybook } = useDeleteDefaultPlaybook();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlaybookId, setSelectedPlaybookId] = useState<string>();

  useEffect(() => {
    getDefaultPlaybooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditClick = (playbookId: string) => {
    setSelectedPlaybookId(playbookId);
    setIsModalOpen(true);
  };

  const handleCreateClick = () => {
    setSelectedPlaybookId(undefined);
    setIsModalOpen(true);
  };

  const handleEditDelete = (playbookId: string) => {
    if (window.confirm("Are you sure you want to delete this playbook?")) {
      deletePlaybook(playbookId);
    }
  };

  useEffect(() => {
    if (!isDeleted) return;
    getDefaultPlaybooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleted]);

  const onClose = () => {
    getDefaultPlaybooks();
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="p-4">
        <Button onClick={handleCreateClick} className="mb-4">
          Create Playbook
        </Button>
        {!defaultPlaybooks ? (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="w-[90%]">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Title</TableHead>
                    <TableHead>Created At</TableHead>
                    <TableHead>Edit</TableHead>
                    <TableHead>Delete</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {defaultPlaybooks.map((p) => (
                    <TableRow key={p.id}>
                      <TableCell>{p.title}</TableCell>
                      <TableCell>{dateTimeToString(p.createdAt)}</TableCell>
                      <TableCell>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleEditClick(p.id)}
                        >
                          <IconEdit className="h-4 w-4" />
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleEditDelete(p.id)}
                        >
                          <IconTrash className="h-4 w-4" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
      </div>
      <EditPlaybookModal
        opened={isModalOpen}
        close={onClose}
        playbookId={selectedPlaybookId}
      />
    </>
  );
}
