import { User } from "lucide-react";
import { UserInputMessageData } from "../../models/message-data/UserInputMessageData";

interface UserInputMessageProps {
  data: UserInputMessageData;
  chatId: string;
}

export default function UserInputMessage({ data }: UserInputMessageProps) {
  return (
    <div className="flex justify-start">
      <div className="flex items-start space-x-2 bg-primary text-primary-foreground rounded-lg p-3 max-w-[85%]">
        <User className="h-5 w-5" />
        <div className="space-y-1">
          <p className="text-sm leading-relaxed">
            {data.input.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                {index < data.input.split("\n").length - 1 && <br />}
              </span>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
}
