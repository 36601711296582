import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { IconEye } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { dateTimeToString } from "../../shared/utils/Dates";
import { useSearchEarningsCalls } from "./api/useSearchEarningsCalls";
import ScrollableText from "../../shared/components/scrollable-text/ScrollableText";

export default function AdminEarningsCalls() {
  const { earningsCalls, searchEarningsCalls } = useSearchEarningsCalls();
  const [symbol, setSymbol] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [quarter, setQuarter] = useState<string>("");
  const [page, setPage] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [content, setContent] = useState("");

  const triggerSearch = () => {
    searchEarningsCalls(
      symbol,
      year ? parseInt(year) : undefined,
      quarter ? parseInt(quarter) : undefined,
      page,
    );
  };

  useEffect(() => {
    triggerSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex justify-center">
        <div className="flex gap-4">
          <Input
            placeholder="Symbol"
            value={symbol}
            onChange={(event) => {
              setSymbol(event.currentTarget.value);
            }}
          />
          <Input
            placeholder="Year"
            value={year}
            onChange={(event) => {
              setYear(event.currentTarget.value);
            }}
          />
          <Input
            placeholder="Quarter"
            value={quarter}
            onChange={(event) => {
              setQuarter(event.currentTarget.value);
            }}
          />
          <Button onClick={triggerSearch}>Search</Button>
        </div>
      </div>
      {!earningsCalls ? (
        <div className="flex justify-center items-center p-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
        </div>
      ) : (
        <>
          <div className="flex justify-center">
            <div className="w-[90%]">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Id</TableHead>
                    <TableHead>Symbol</TableHead>
                    <TableHead>Year</TableHead>
                    <TableHead>Quarter</TableHead>
                    <TableHead>Date</TableHead>
                    <TableHead>Content</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {earningsCalls &&
                    earningsCalls.map((ec) => (
                      <TableRow key={ec.id}>
                        <TableCell>{ec.id}</TableCell>
                        <TableCell>{ec.symbol}</TableCell>
                        <TableCell>{ec.year}</TableCell>
                        <TableCell>{ec.quarter}</TableCell>
                        <TableCell>{dateTimeToString(ec.date)}</TableCell>
                        <TableCell>
                          <Button
                            size="icon"
                            onClick={() => {
                              setContent(ec.content);
                              setDialogOpen(true);
                            }}
                          >
                            <IconEye className="h-4 w-4" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </div>

          <div className="flex justify-center gap-4 mt-4">
            <Button
              onClick={() => {
                setPage(page - 1);
              }}
            >
              Previous Page
            </Button>
            <Button
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Next Page
            </Button>
          </div>

          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogContent className="max-w-[80vw]">
              <DialogHeader>
                <DialogTitle>Content</DialogTitle>
              </DialogHeader>
              <ScrollableText content={content} />
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
}
