import { toast } from "sonner";

export function errorNotification(title: string, message?: string) {
  toast.error(title, {
    description: message,
  });
}

export function successNotification(title: string, message?: string) {
  toast.success(title, {
    description: message,
  });
}
