import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetChatsFeedback } from "./api/useGetChatsFeedback";
import { IconEye } from "@tabler/icons-react";
import { ChatFeedback } from "./models/ChatFeedback";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

export default function AdminChatsFeedback() {
  const { chatsFeedback, getChatsFeedback } = useGetChatsFeedback();
  const navigate = useNavigate();

  useEffect(() => {
    getChatsFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewChatClick = (feedback: ChatFeedback) => {
    navigate(`/admin/user/${feedback.userId}/chat/${feedback.chatId}`);
  };

  return (
    <div className="flex justify-center">
      <div className="w-[90%]">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>UserId</TableHead>
              <TableHead>Chat title</TableHead>
              <TableHead>Comment</TableHead>
              <TableHead>See chat</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {chatsFeedback?.map((feedback) => (
              <TableRow key={feedback.userId}>
                <TableCell>{feedback.userId}</TableCell>
                <TableCell>{feedback.chatTitle}</TableCell>
                <TableCell>{feedback.comment}</TableCell>
                <TableCell>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleViewChatClick(feedback)}
                  >
                    <IconEye className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
