interface CenterLoaderProps {
  size?: "sm" | "default";
}

export default function CenterLoader({ size = "default" }: CenterLoaderProps) {
  return (
    <div
      className={`flex w-full items-center justify-center ${size === "default" ? "h-screen" : "h-16"}`}
    >
      <div className="flex flex-col items-center space-y-2">
        <div
          className={`animate-spin rounded-full border-4 border-gray-300 border-t-gray-900 ${size === "default" ? "h-12 w-12" : "h-6 w-6"}`}
        />
        {size === "default" && (
          <p className="text-gray-500 dark:text-gray-400">Loading...</p>
        )}
      </div>
    </div>
  );
}
