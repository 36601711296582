import { useEffect, useState } from "react";
import { useGetShortLatestVersions } from "./api/useGetShortLatestVersions";
import { useGetLatestVersion } from "./api/useGetLatestVersion";
import { PromptType } from "./enums/PromptType";
import { useCreatePromptVersion } from "./api/useCreatePromptVersion";
import { useGetAllPromptVersions } from "./api/useGetAllPromptVersions";
import { PromptVersion } from "./models/PromptVersion";
import PromptVersionView from "./components/PromptVersionView";
import Editor from "@monaco-editor/react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

export default function AdminPrompts() {
  const { latestVersion, getLatestVersion } = useGetLatestVersion();
  const { shortLatestVersions, getShortLatestVersions } =
    useGetShortLatestVersions();
  const { createdPromptVersion, createPromptVersion } =
    useCreatePromptVersion();
  const { promptVersions, getAllPromptVersions } = useGetAllPromptVersions();
  const [promptName, setPromptName] = useState<null | PromptType>(null);
  const [promptText, setPromptText] = useState<string>("");
  const [promptDescription, setPromptDescription] = useState<string | null>("");
  const [historyOpened, setHistoryOpened] = useState(false);
  const [temperature, setTemperature] = useState<number>(0.65);
  const [maxTokens, setMaxTokens] = useState<number>(4096);

  useEffect(() => {
    getShortLatestVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (promptName !== null) {
      getLatestVersion(promptName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptName]);

  useEffect(() => {
    if (!latestVersion) return;
    setPromptText(latestVersion.text);
    setPromptDescription(latestVersion.description);
    setTemperature(latestVersion.temperature);
    setMaxTokens(latestVersion.maxTokens);
  }, [latestVersion]);

  useEffect(() => {
    if (!createdPromptVersion) return;

    setPromptText(createdPromptVersion.text);
    setPromptDescription(createdPromptVersion.description);
    setTemperature(createdPromptVersion.temperature);
    setMaxTokens(createdPromptVersion.maxTokens);
  }, [createdPromptVersion]);

  const handlePromptVersionClick = (promptName: PromptType) => {
    setPromptName(promptName);
    return undefined;
  };

  const handleSaveClick = () => {
    if (promptName === null) return;
    if (
      window.confirm(
        "Are you sure you want to create a new version of this prompt? This new version will be used immediately.",
      )
    ) {
      createPromptVersion(
        promptName,
        promptText,
        promptDescription,
        temperature,
        maxTokens,
      );
    }
  };

  const handleHistoryClick = () => {
    if (promptName === null) return;
    getAllPromptVersions(promptName);
    setHistoryOpened(true);
  };

  const revertToVersion = (version: PromptVersion) => {
    if (promptName === null) return;
    if (
      window.confirm(
        "Are you sure you want to revert to this version? This will create a new version with the same content.",
      )
    ) {
      createPromptVersion(
        promptName,
        version.text,
        version.description,
        version.temperature,
        version.maxTokens,
      );
    }
  };

  return (
    <div className="p-4">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-4">
          <Accordion type="single" collapsible>
            {shortLatestVersions?.map((version) => (
              <AccordionItem
                key={version.name}
                value={version.name}
                onClick={() => handlePromptVersionClick(version.name)}
              >
                <AccordionTrigger>{version.name}</AccordionTrigger>
                <AccordionContent>
                  <div className="flex flex-col space-y-4">
                    <div className="flex justify-between items-center">
                      <div className="text-sm text-muted-foreground">
                        Last updated: {version.createdAt.toString()}
                      </div>
                      <div className="flex space-x-2">
                        <Button onClick={handleHistoryClick}>History</Button>
                        <Button onClick={handleSaveClick}>Save</Button>
                      </div>
                    </div>

                    <div className="flex flex-col space-y-4">
                      <div>
                        <div className="mb-2">Description:</div>
                        <Textarea
                          value={promptDescription || ""}
                          onChange={(e) =>
                            setPromptDescription(e.currentTarget.value)
                          }
                          placeholder="Enter description"
                          className="h-24"
                        />
                      </div>

                      <div>
                        <div className="mb-2">Temperature:</div>
                        <Input
                          type="number"
                          value={temperature}
                          onChange={(e) =>
                            setTemperature(parseFloat(e.currentTarget.value))
                          }
                          min={0}
                          max={2}
                          step={0.01}
                          className="max-w-xs"
                        />
                      </div>

                      <div>
                        <div className="mb-2">Max tokens:</div>
                        <Input
                          type="number"
                          value={maxTokens}
                          onChange={(e) =>
                            setMaxTokens(parseInt(e.currentTarget.value))
                          }
                          min={1}
                          max={32000}
                          step={1}
                          className="max-w-xs"
                        />
                      </div>

                      <div>
                        <div className="mb-2">Prompt text:</div>
                        <div className="h-96 border rounded-md">
                          <Editor
                            height="100%"
                            defaultLanguage="markdown"
                            value={promptText}
                            onChange={(value) => setPromptText(value || "")}
                            options={{
                              minimap: { enabled: false },
                              wordWrap: "on",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>

      <Dialog open={historyOpened} onOpenChange={setHistoryOpened}>
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>Prompt History</DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[600px]">
            <Accordion type="single" collapsible>
              {promptVersions?.map((version, index) => (
                <PromptVersionView
                  key={version.id}
                  version={version}
                  index={index}
                  promptVersions={promptVersions}
                  revertToVersion={revertToVersion}
                />
              ))}
            </Accordion>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </div>
  );
}
