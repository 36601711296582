export class PlaybookShort {
  public id: string;
  public title: string;
  public createdAt: Date;
  public updatedAt: Date;

  public constructor(data: any) {
    this.id = data.id;
    this.title = data.title;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }
}

export class Playbook extends PlaybookShort {
  public steps: PlaybookStep[];
  public description?: string;

  public constructor(data: any) {
    super(data);
    this.steps = data.steps.map((step: any) => new PlaybookStep(step));
    this.description = data.description;
  }
}

export class PlaybookStepShort {
  public id: string;
  public title: string;
  public prompt: string;
  public order: number;

  public constructor(data: any) {
    this.id = data.id;
    this.title = data.title;
    this.prompt = data.prompt;
    this.order = data.order;
  }
}

export class PlaybookStep extends PlaybookStepShort {
  public playbookId: string;
  public createdAt: Date;
  public updatedAt: Date;

  public constructor(data: any) {
    super(data);
    this.playbookId = data.playbookId;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }
}
