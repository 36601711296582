import { useEffect, useMemo, memo } from "react";
import ChatItem from "./ChatItem";
import { ChatShort } from "../../../shared/models/Chat";
import { useMetadataResolver } from "../hooks/useMetadataResolver";

interface ChatItemListProps {
  chats: ChatShort[];
  userId?: string;
  onChatClick?: (chat: ChatShort) => void;
  lastChatRef?: (node: HTMLDivElement) => void;
}

function ChatItemList({
  chats,
  userId,
  lastChatRef,
  onChatClick,
}: ChatItemListProps) {
  const { resolveMetadata, cache } = useMetadataResolver();

  useEffect(() => {
    const resolveAllMetadata = async () => {
      for (const chat of chats) {
        if (chat.metadata) {
          await resolveMetadata(
            chat.metadata.companyIds || "",
            chat.metadata.filingIds || "",
            chat.metadata.transcriptIds || "",
          );
        }
      }
    };

    resolveAllMetadata();
  }, [chats, resolveMetadata]);

  const getResolvedMetadata = useMemo(
    () => (chat: ChatShort) => {
      if (!chat.metadata) return undefined;

      const companies = chat.metadata.companyIds
        ?.split(",")
        .filter(Boolean)
        .map((id) => cache.companies[id])
        .filter(Boolean);

      const documents = [
        ...(chat.metadata.filingIds
          ?.split(",")
          .filter(Boolean)
          .map((id) => cache.documents[`filing-${id}`]) || []),
        ...(chat.metadata.transcriptIds
          ?.split(",")
          .filter(Boolean)
          .map((id) => cache.documents[`transcript-${id}`]) || []),
      ].filter(Boolean);

      return {
        companies,
        documents,
      };
    },
    [cache],
  );

  return (
    <div className="space-y-4">
      {chats.map((chat, index) => {
        const isLast = index === chats.length - 1;
        return (
          <div
            key={chat.id}
            ref={isLast ? lastChatRef : undefined}
            className="transition-opacity duration-200"
          >
            <ChatItem
              chat={chat}
              isActive={false}
              userId={userId}
              onChatClick={onChatClick}
              resolvedMetadata={getResolvedMetadata(chat)}
            />
          </div>
        );
      })}
    </div>
  );
}

export default memo(ChatItemList);
