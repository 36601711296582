import {
  IconUser,
  IconMessages,
  IconScript,
  IconPlayerPlay,
  IconTextPlus,
} from "@tabler/icons-react";
import AdminChatsFeedback from "../features/admin-chats-feedback/AdminChatsFeedback";
import AdminUsersList from "../features/admin-users-list/AdminUsersList";
import AdminEarningsCalls from "../features/adming-earnings-calls/AdminEarningsCalls";
import AdminPlaybooks from "../features/admin-playbooks/AdminPlaybooks";
import AdminPrompts from "../features/admin-prompts/AdminPrompts";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Settings, ArrowLeft } from "lucide-react";
import SettingsModal from "../features/header/components/Settings";
import { useNavigate } from "react-router-dom";

export default function AdminDashboard() {
  const [settingsOpened, setSettingsOpened] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="bg-white border-b border-gray-100">
        <div className="container mx-auto max-w-7xl px-8 py-6">
          <div className="flex justify-between items-center">
            <div>
              <div className="flex items-center gap-3 mb-1">
                <Button
                  variant="ghost"
                  size="sm"
                  className="text-gray-600 gap-1.5 text-sm h-8"
                  onClick={() => navigate("/")}
                >
                  <ArrowLeft className="h-4 w-4" />
                  Back to Home
                </Button>
              </div>
              <h1 className="text-2xl font-bold text-gray-900">
                Admin Dashboard
              </h1>
              <p className="text-gray-500 text-base">
                Manage users, chats, and system settings
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                size="sm"
                className="text-gray-600 gap-1.5 text-sm h-8"
                onClick={() => setSettingsOpened(true)}
              >
                <Settings className="h-4 w-4" />
                Settings
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto max-w-7xl px-8 py-6">
        <Tabs defaultValue="users" className="w-full">
          <TabsList className="flex h-10 items-center justify-start gap-1">
            <TabsTrigger value="users" className="flex items-center gap-2">
              <IconUser className="h-4 w-4" />
              Users
            </TabsTrigger>
            <TabsTrigger value="feedback" className="flex items-center gap-2">
              <IconMessages className="h-4 w-4" />
              Chat feedback
            </TabsTrigger>
            <TabsTrigger
              value="earningsCalls"
              className="flex items-center gap-2"
            >
              <IconScript className="h-4 w-4" />
              Earnings calls
            </TabsTrigger>
            <TabsTrigger value="playbooks" className="flex items-center gap-2">
              <IconPlayerPlay className="h-4 w-4" />
              Playbooks
            </TabsTrigger>
            <TabsTrigger value="prompts" className="flex items-center gap-2">
              <IconTextPlus className="h-4 w-4" />
              Prompts
            </TabsTrigger>
          </TabsList>

          <TabsContent value="users" className="mt-4">
            <AdminUsersList />
          </TabsContent>
          <TabsContent value="feedback" className="mt-4">
            <AdminChatsFeedback />
          </TabsContent>
          <TabsContent value="earningsCalls" className="mt-4">
            <AdminEarningsCalls />
          </TabsContent>
          <TabsContent value="playbooks" className="mt-4">
            <AdminPlaybooks />
          </TabsContent>
          <TabsContent value="prompts" className="mt-4">
            <AdminPrompts />
          </TabsContent>
        </Tabs>
      </div>

      <SettingsModal
        opened={settingsOpened}
        close={() => setSettingsOpened(false)}
      />
    </div>
  );
}
