import ReactApexChart from "react-apexcharts";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ChartType } from "./enums/ChartType";
import { Chart, HeatmapChart, TableChart, SimpleChart } from "./models/Chart";

interface ChartVisualisationProps {
  chart: Chart;
  small?: boolean;
}

export default function ChartVisualisation({
  chart,
  small = false,
}: ChartVisualisationProps) {
  if (!chart) {
    return null;
  }

  switch (chart.type) {
    case ChartType.HeatMap: {
      const heatmapChart = chart as HeatmapChart;
      return (
        <>
          {heatmapChart.options.title.text && (
            <h3 className="text-center font-semibold">
              {heatmapChart.options.title.text}
            </h3>
          )}
          <div className="hidden lg:block">
            <div
              className={`w-[500px] h-[320px] xl:w-[830px] xl:h-[520px] lg:w-[730px] lg:h-[450px] ${small ? "w-full h-1/2" : ""}`}
            >
              <ReactApexChart
                series={heatmapChart.series as any}
                options={heatmapChart.options as any}
                type={heatmapChart.type as any}
              />
            </div>
          </div>
        </>
      );
    }
    case ChartType.Table: {
      const tableChart = chart as TableChart;
      return (
        <ScrollArea className="h-[400px]">
          <Table>
            {tableChart.caption && (
              <TableCaption>{tableChart.caption}</TableCaption>
            )}
            <TableHeader>
              <TableRow>
                {tableChart.options.map((option: string, index: number) => (
                  <TableHead key={index}>{option}</TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {tableChart.series.map((row: string[], index: number) => (
                <TableRow key={index}>
                  {row.map((value, subIndex) => (
                    <TableCell key={subIndex}>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      );
    }
    default: {
      const simpleChart = chart as SimpleChart;
      return (
        <>
          {simpleChart.options.title.text && (
            <h3 className="text-center font-semibold">
              {simpleChart.options.title.text}
            </h3>
          )}
          <div className="hidden md:block">
            <div
              className={`w-[500px] h-[320px] xl:w-[830px] xl:h-[520px] lg:w-[730px] lg:h-[450px] ${small ? "w-full h-1/2" : ""}`}
            >
              <ReactApexChart
                series={simpleChart.series as any}
                options={simpleChart.options as any}
                type={simpleChart.type as any}
              />
            </div>
          </div>
        </>
      );
    }
  }
}
