import { ChatMessage } from "../models/ChatMessage";
import { MessageDataType } from "../enums/MessageDataType";
import { ModelOutputMessageData } from "../models/message-data/ModelOutputMessageData";
import { FunctionCallMessageData } from "../models/message-data/FunctionCallMessageData";
import { MessageProcessor } from "../models/MessageDataProcessor";
import ModelOutputMessage from "./messages/ModelOutputMessage";
import { cn } from "@/lib/utils";
import { Loader2, CheckCircle2, AlertCircle, ChevronDown } from "lucide-react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Button } from "@/components/ui/button";
import { useState } from "react";

interface ExecutionPlanProps {
  messages: ChatMessage[];
}

const functionCallTitles = new Map<string, string>([
  ["ask_company_documents", "Reading through company documents"],
  ["ask_agent", "Reading through company documents"],
  ["ask_user_documents", "Reading through uploaded documents"],
  ["ask_agent_uploaded_files", "Reading through uploaded documents"],
  ["filter_documents", "Filtering documents"],
  ["search_companies", "Searching for companies"],
  ["get_market_data", "Getting market data"],
  ["execute_code", "Executing code"],
  ["calculate", "Crunching numbers"],
  ["get_news", "Getting news"],
  ["search_internet", "Searching the web"],
  ["get_estimates", "Getting estimates"],
]);

export function ExecutionPlanNew({ messages }: ExecutionPlanProps) {
  const [isOpen, setIsOpen] = useState(false);
  const isPlanFinished = messages[messages.length - 1].finishedAt !== undefined;
  const finishedTitle = "Research completed";
  const workingTitle = "Researching...";
  const accordionTitle = isPlanFinished ? finishedTitle : workingTitle;

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="border rounded-lg bg-muted/50 font-mono"
    >
      <CollapsibleTrigger asChild>
        <Button
          variant="ghost"
          className="w-full justify-between p-1.5 hover:no-underline font-normal border-b"
        >
          <span className="text-sm">{accordionTitle}</span>
          <ChevronDown
            className={cn(
              "h-4 w-4 transition-transform duration-200",
              isOpen && "transform rotate-180",
            )}
          />
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent className="px-4 py-2">
        <div className="space-y-1.5">
          {messages.map((message) => {
            if (message.data?.type !== MessageDataType.ModelOutputMessageData)
              return null;

            const steps = [];
            const data = message.data as ModelOutputMessageData;
            const functionCalls = data.functionCalls!;
            const isLoading = message.finishedAt === undefined;

            // Model output step
            steps.push(
              <div key={message.id} className="relative pl-6">
                <div className="pb-1.5">
                  <div className="absolute left-0 -translate-x-[11px] flex h-6 w-6 items-center justify-center">
                    {isLoading ? (
                      <div className="bg-muted/50 rounded-full p-1">
                        <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
                      </div>
                    ) : (
                      <div className="bg-muted/50 rounded-full p-1">
                        <CheckCircle2 className="h-4 w-4 text-primary" />
                      </div>
                    )}
                  </div>
                  <div className="space-y-0.5">
                    <div className="text-sm">Model output</div>
                    <div className="text-sm text-muted-foreground">
                      <ModelOutputMessage data={data} />
                    </div>
                  </div>
                </div>
              </div>,
            );

            // Function call steps
            functionCalls.forEach((call) => {
              const functionCallMessage = messages.find(
                (m) =>
                  (m.data as FunctionCallMessageData)?.functionCall
                    ?.functionCall?.id === call.id,
              );
              const isLoading = functionCallMessage?.finishedAt === undefined;
              const hasFailed =
                functionCallMessage?.error !== undefined &&
                functionCallMessage.error !== null;

              steps.push(
                <div key={call.id} className="relative pl-6">
                  <div className="pb-1.5">
                    <div className="absolute left-0 -translate-x-[11px] flex h-6 w-6 items-center justify-center">
                      {isLoading ? (
                        <div className="bg-muted/50 rounded-full p-1">
                          <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
                        </div>
                      ) : hasFailed ? (
                        <div className="bg-muted/50 rounded-full p-1">
                          <AlertCircle className="h-4 w-4 text-muted-foreground" />
                        </div>
                      ) : (
                        <div className="bg-muted/50 rounded-full p-1">
                          <CheckCircle2 className="h-4 w-4 text-primary" />
                        </div>
                      )}
                    </div>
                    <div className="space-y-0.5">
                      <div
                        className={cn(
                          "text-sm",
                          hasFailed && "text-muted-foreground",
                        )}
                      >
                        {functionCallTitles.get(call.name)}
                      </div>
                      {functionCallMessage?.data && (
                        <div className="text-sm text-muted-foreground">
                          {MessageProcessor.GetComponent(
                            functionCallMessage.data,
                            isLoading,
                          )}
                        </div>
                      )}
                      {hasFailed && functionCallMessage?.error && (
                        <div className="text-sm text-muted-foreground">
                          {functionCallMessage.error}
                        </div>
                      )}
                    </div>
                  </div>
                </div>,
              );
            });

            return steps;
          })}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}
