import { Badge } from "@/components/ui/badge";
import { useTimer } from "./useTimer";
import { timeIntervalToString } from "../../utils/Dates";

interface TimerProps {
  startedAt: Date;
  finishedAt: Date | undefined;
  variant: "neutral" | "success";
  hasError?: boolean;
}

export default function Timer({
  startedAt,
  finishedAt,
  variant,
  hasError,
}: TimerProps) {
  const { timerValue } = useTimer(startedAt, finishedAt);

  // Map the variants to match shadcn's badge variants
  const variantMap = {
    neutral: "secondary",
    success: "default",
  } as const;

  return (
    <Badge
      variant={variantMap[variant]}
      className={hasError ? "bg-destructive text-destructive-foreground" : ""}
    >
      {timeIntervalToString(timerValue)}
    </Badge>
  );
}
