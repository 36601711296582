import { PlaybookStepShort } from "../model/Playbook";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { v4 as uuidv4 } from "uuid";
import { IconMenuOrder } from "@tabler/icons-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import PlaybookStepEdit from "./PlaybookStepEdit";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface PlaybookStepsManagementProps {
  playbookSteps: PlaybookStepShort[];
  draggableSteps: PlaybookStepShort[];
  setState: React.Dispatch<React.SetStateAction<PlaybookStepShort[]>>;
  reorder: (args: { from: number; to: number }) => void;
  isReadOnly?: boolean;
  playbookId: string;
}

export default function PlaybookStepsManagement({
  playbookSteps,
  draggableSteps,
  setState,
  reorder,
  isReadOnly = false,
  playbookId,
}: PlaybookStepsManagementProps) {
  const addSection = () => {
    setState((steps) => {
      const newStep = {
        id: uuidv4(),
        title: `Step ${draggableSteps.length + 1}`,
        prompt: "",
        order: draggableSteps.length,
      };

      const updatedSections = [...(steps || [])];
      updatedSections.push(newStep);

      return updatedSections;
    });
  };

  const updateSection = (id: string, newTitle: string, newPrompt: string) => {
    setState((steps) =>
      steps?.map((step) =>
        step.id === id ? { ...step, title: newTitle, prompt: newPrompt } : step,
      ),
    );
  };

  const removeSection = (id: string) => {
    setState((steps) => steps.filter((step) => step.id !== id));
  };

  const items = draggableSteps.map((step, index) => (
    <Draggable
      key={step.id}
      index={index}
      draggableId={step.id}
      isDragDisabled={isReadOnly}
    >
      {(provided, snapshot) => (
        <div
          className={cn(
            "flex items-center mb-3 rounded-sm bg-white",
            snapshot.isDragging && "shadow-lg shadow-black/5",
          )}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {!isReadOnly && (
            <div
              {...provided.dragHandleProps}
              className="flex items-center justify-center h-full px-4 text-gray-600"
            >
              <IconMenuOrder size={16} className="text-tertiary-5" />
            </div>
          )}
          <div className="flex-1">
            <PlaybookStepEdit
              key={step.id}
              step={step}
              initialStep={
                playbookSteps.find(
                  (s: PlaybookStepShort) => s.id === step.id,
                ) || new PlaybookStepShort({})
              }
              updateSection={updateSection}
              removeSection={() => removeSection(step.id)}
              isReadOnly={isReadOnly}
              playbookId={playbookId}
            />
          </div>
        </div>
      )}
    </Draggable>
  ));

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) =>
        reorder({
          from: source.index,
          to: destination?.index || 0,
        })
      }
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {!isReadOnly && (
        <div className="flex justify-center mt-4">
          <Button
            variant="secondary"
            size={isLargeScreen ? "default" : "sm"}
            className="rounded-sm w-[110px] xl:w-[145px]"
            onClick={addSection}
          >
            Add Step
          </Button>
        </div>
      )}
    </DragDropContext>
  );
}
