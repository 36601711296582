import { useGetDefaultPlaybook } from "../api/useGetDefaultPlaybook";
import { useEffect, useState } from "react";
import PlaybookStepsManagement from "../../playbook/components/PlaybookStepsManagement";
import { PlaybookStepShort } from "../../playbook/model/Playbook";
import { useCreateDefaultPlaybook } from "../api/useCreateDefaultPlaybook";
import { useUpdateDefaultPlaybook } from "../api/useUpdateDefaultPlaybook";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

interface EditPlaybookModalProps {
  opened: boolean;
  close: () => void;
  playbookId?: string;
}

export default function EditPlaybookModal({
  opened,
  close,
  playbookId,
}: EditPlaybookModalProps) {
  const { defaultPlaybook, getDefaultPlaybook, setDefaultPlaybook } =
    useGetDefaultPlaybook();
  const [steps, setSteps] = useState<PlaybookStepShort[]>([]);
  const [newPlaybookTitle, setNewPlaybookTitle] = useState("");
  const { createPlaybook } = useCreateDefaultPlaybook();
  const { updatePlaybook } = useUpdateDefaultPlaybook();

  useEffect(() => {
    if (!playbookId) return;
    getDefaultPlaybook(playbookId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbookId]);

  useEffect(() => {
    if (!defaultPlaybook) return;
    setNewPlaybookTitle(defaultPlaybook.title);
    setSteps(defaultPlaybook.steps);
  }, [defaultPlaybook]);

  const handleSaveClick = async () => {
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      order: index,
    }));
    if (!defaultPlaybook) {
      await createPlaybook(newPlaybookTitle, updatedSteps);
    } else {
      await updatePlaybook(defaultPlaybook.id, newPlaybookTitle, updatedSteps);
    }

    onClose();
  };

  const onClose = () => {
    setDefaultPlaybook(null);
    setNewPlaybookTitle("");
    setSteps([]);
    close();
  };

  const reorder = (args: { from: number; to: number }) => {
    setSteps((prev) => {
      const newSteps = [...prev];
      const [removed] = newSteps.splice(args.from, 1);
      newSteps.splice(args.to, 0, removed);
      return newSteps;
    });
  };

  return (
    <Dialog open={opened} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Edit Playbook</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <Input
            value={newPlaybookTitle}
            placeholder="Please enter a valid title"
            className={
              newPlaybookTitle.trim() === "" ? "border-destructive" : ""
            }
            autoFocus
            onChange={(event) => setNewPlaybookTitle(event.currentTarget.value)}
          />
          <div>
            <h5 className="font-medium mb-2">Steps</h5>
            <ScrollArea className="h-[400px] pr-4">
              <PlaybookStepsManagement
                playbookSteps={defaultPlaybook?.steps || []}
                draggableSteps={steps}
                setState={setSteps}
                reorder={reorder}
                playbookId={defaultPlaybook?.id ?? ""}
              />
            </ScrollArea>
          </div>
          <div className="flex justify-end">
            <Button onClick={handleSaveClick}>Save</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
