import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Chat } from "../../../shared/models/Chat";
import { useCallback } from "react";

export const useGetAdminChat = (chatId: string, userId: string) => {
  const {
    data: chat,
    setData: setChat,
    call,
  } = useApiPrivateRequest<Chat>((response) => new Chat(response));

  const getAdminChat = useCallback(async () => {
    await call(`/admin/chat/${chatId}`, "GET", { userId });
  }, [call, chatId, userId]);

  return { chat, setChat, getAdminChat };
};
