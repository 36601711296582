import { useState } from "react";
import { IconTrash } from "@tabler/icons-react";
import { EditableTextInput } from "../../../shared/components/editable-text/EditableTextInput";
import { PlaybookStepShort } from "../model/Playbook";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Wand2 } from "lucide-react";
import { useImprovePlaybookStep } from "../api/useImprovePlaybookStep";
import { useToast } from "@/hooks/use-toast";

interface PlaybookStepProps {
  step: PlaybookStepShort;
  initialStep: PlaybookStepShort;
  updateSection: (id: string, newTitle: string, newPrompt: string) => void;
  removeSection: () => void;
  isReadOnly?: boolean;
  playbookId: string;
}

export default function PlaybookStepEdit({
  step,
  initialStep,
  updateSection,
  removeSection,
  isReadOnly = false,
  playbookId,
}: PlaybookStepProps) {
  const [newTitle, setNewTitle] = useState(step.title);
  const [newPrompt, setNewPrompt] = useState(step.prompt);
  const { improvePlaybookStep, isLoading: isImproving } =
    useImprovePlaybookStep();
  const { toast } = useToast();

  const handleImprovePrompt = async () => {
    if (playbookId === "new") {
      toast({
        title: "Error",
        description: "Please save the playbook first before improving prompts.",
        variant: "destructive",
      });
      return;
    }

    try {
      console.log("Improving prompt for step:", step.id);
      const improvedPrompt = await improvePlaybookStep(playbookId, step.id);
      if (improvedPrompt) {
        setNewPrompt(improvedPrompt);
        updateSection(step.id, newTitle, improvedPrompt);
        toast({
          title: "Success",
          description: "Research prompt has been improved.",
        });
      }
    } catch {
      toast({
        title: "Error",
        description:
          "There was an error improving the prompt. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex flex-col gap-2 p-2">
      <div className="flex justify-between items-center">
        {isReadOnly ? (
          <p className="font-bold text-sm">{newTitle}</p>
        ) : (
          <EditableTextInput
            text={newTitle}
            placeholder="Please enter a valid title"
            textClassname="font-bold text-sm"
            inputClassname="w-[250px] text-sm"
            onSave={(value: string) => {
              if (value !== step.title) {
                setNewTitle(value);
                updateSection(step.id, value, newPrompt);
              }
            }}
          />
        )}
        {!isReadOnly && (
          <Button
            variant="secondary"
            size="icon"
            className="h-8 w-8"
            onClick={removeSection}
          >
            <IconTrash size={18} stroke={1.5} className="text-black" />
          </Button>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <div>
          <div className="flex items-center justify-between mb-2">
            <h4 className="text-sm font-medium text-gray-700">
              Research Prompt
            </h4>
            {!isReadOnly && (
              <Button
                type="button"
                variant="ghost"
                size="sm"
                onClick={handleImprovePrompt}
                disabled={isImproving}
                className="text-gray-600 hover:text-gray-900"
              >
                <Wand2
                  className={`h-3.5 w-3.5 mr-1.5 ${isImproving ? "animate-spin" : ""}`}
                />
                {isImproving ? "Improving..." : "Improve"}
              </Button>
            )}
          </div>
          {isReadOnly ? (
            <div className="bg-gray-50 p-4 rounded-md text-sm border border-gray-100">
              {newPrompt}
            </div>
          ) : (
            <Textarea
              value={newPrompt}
              onChange={(event) => {
                setNewPrompt(event.target.value);
                updateSection(step.id, newTitle, event.target.value);
              }}
              placeholder="Please enter a valid prompt"
              className={cn(
                "text-sm px-5 py-4 min-h-[38px] max-h-[152px] resize-none",
                newPrompt === "" && "border-red-500",
                newPrompt !== initialStep.prompt &&
                  newPrompt !== "" &&
                  "border-primary",
              )}
              autoFocus
            />
          )}
        </div>
      </div>
    </div>
  );
}
